import React, { Component } from 'react';
import {Link} from 'gatsby';

import Scrollspy from 'react-scrollspy';
import  LydiaWoodLogo  from '@images/logos/LydiaWoodLogo.png';


import { Container } from '@components/global';
import {
  Nav,
  NavItem,
  Brand,
  StyledContainer,
  NavListWrapper,
  MobileMenu,
  Mobile,
  Logo
} from './style';

import { ReactComponent as MenuIcon } from '@static/icons/menu.svg';

const NAV_ITEMS = [{title:'Home', path:''},{title:'About', path: 'about'}, {title:'Shop', path: 'shop'}, {title:'Contact', path: 'contact'}, {title:'FAQ', path: 'faq'}];

class Navbar extends Component {
  state = {
    mobileMenuOpen: false,
  };

  toggleMobileMenu = () => {
    this.setState(prevState => ({ mobileMenuOpen: !prevState.mobileMenuOpen }));
  };

  closeMobileMenu = () => {
    if (this.state.mobileMenuOpen) {
      this.setState({ mobileMenuOpen: false });
    }
  };

  getNavAnchorLink = item => (
    <Link href={`/${item.path}`} onClick={this.closeMobileMenu}>
      {item.title}
    </Link>
  );

  getNavList = ({ mobile = false }) => (
    <NavListWrapper mobile={mobile}>
      <Scrollspy
        items={NAV_ITEMS.map(item => item.title.toLowerCase())}
        currentClassName="active"
        mobile={mobile}
        offset={-64}
      >
        {NAV_ITEMS.map(navItem => (
          <NavItem key={navItem.title}>{this.getNavAnchorLink(navItem)}</NavItem>
        ))}
      </Scrollspy>
    </NavListWrapper>
  );

  render() {
    
    const { mobileMenuOpen } = this.state;

    return (
      <Nav {...this.props}>
        <Brand>
          <Link href={'/'} ><Logo src={LydiaWoodLogo} alt='Lydia Wood logo'/></Link>
          </Brand>
        <StyledContainer>
          <Mobile>
            <button onClick={this.toggleMobileMenu} style={{ color: 'black' }}>
              <MenuIcon />
            </button>
          </Mobile>

          <Mobile hide>{this.getNavList({})}</Mobile>
        </StyledContainer>
        <Mobile>
          {mobileMenuOpen && (
            <MobileMenu>
              <Container>{this.getNavList({ mobile: true })}</Container>
            </MobileMenu>
          )}
        </Mobile>
      </Nav>
    );
  }
}

export default Navbar;
