import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql, Link } from 'gatsby';


import { Container } from '@components/global';
import ExternalLink from '@common/ExternalLink';

import EtsyIcon from '@static/icons/etsy.svg';
import InstagramIcon from '@static/icons/instagram.svg';

const SOCIAL = [
  {
    icon: EtsyIcon,
    link: 'https://www.etsy.com/uk/shop/LydiaWoodJewellery',
  },
  {
    icon: InstagramIcon,
    link: 'https://www.instagram.com/lydiawoodjewellery/',
  },
];

const Footer = () => (
  <StaticQuery
    query={graphql`
      query {
        art_pot: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "customers_pot" }
        ) {
          childImageSharp {
            fluid(maxWidth: 960) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (
      <React.Fragment>
        <FooterWrapper>
          <StyledContainer>
            <Copyright>
              <h2>Lydia Wood Jewellery</h2>
              <span>
              <Link href={`/`}>Home </Link> 
              <Link href={`/about`}>About </Link> 
              <Link href={`/shop`}>Shop </Link> 
              <Link href={`/contact`}>Contact </Link>
              <Link href={`/faq`}>FAQ</Link>
              </span>

            </Copyright>
            <SocialIcons>
              {SOCIAL.map(({ icon, link }) => (
                <ExternalLink key={link} href={link}>
                  <img src={icon} alt={icon} />
                </ExternalLink>
              ))}
            </SocialIcons>
          </StyledContainer>
        </FooterWrapper>
      </React.Fragment>
    )}
  />
);

const SocialIcons = styled.div`
  display: flex;

  img {
    margin: 0 8px;
    width: 24px;
    height: 24px;
  }

  @media (max-width: ${props => props.theme.screen.sm}) {
    margin-top: 40px;
  }
`;

const FooterWrapper = styled.footer`
  background-color: ${props => props.theme.color.blue};
  padding: 32px 0;
  bottom: 0;
  width: 100%;
`;

const Copyright = styled.div`
  ${props => props.theme.font_size.small};
  color: ${props => props.theme.color.white.regular};

  h2{
    font-family: ${props => props.theme.font.primary}
  }
  span{
    font-family: ${props => props.theme.font.secondary};
  }

  a {
    text-decoration: none;
    color: inherit;
  }
`;

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${props => props.theme.screen.sm}) {
    flex-direction: column;
    text-align: center;
  }
`;

export default Footer;
